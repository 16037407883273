<template lang="pug">
  v-col(cols=12 md=4 ).pa-0 
    //-Móvil 
    
    v-row.justify-center.align-center.d-md-none
      v-col.d-none.d-md-block.mr-12-pr-12
        v-container(align='center' justify='center').ml-8
            v-row.mb-3.ml-1
                h2.white--text.mr-1.font-weight-light Mapa de sitio
            v-row.ma-0.pa-0.ml-1(v-for='{texto, ruta}, i in Navegador.rutas' :key='i'  @click='dirigir_a(ruta)')
                v-icon(v-if='ruta==ruta_actual' x-small left dark absolute).rojo--text fas fa-angle-right
                v-icon(v-else x-small left dark absolute).white--text fas fa-angle-right
                div.puntor
                  h3(v-if='ruta==ruta_actual' v-html='texto').rojo--text.font-weight-medium 
                  h3(v-else v-html='texto').white--text.font-weight-medium 

    //-PC
    v-col.d-none.d-md-block.ml-12
      v-container
        v-row.justify-start.align-start.mb-2.ml-1.ml-12
          h2.white--text.mr-1.font-weight-light Mapa de sitio
        v-row.ma-0.pa-0(v-for='{texto, ruta}, i in Navegador.rutas' :key='i'  @click='dirigir_a(ruta)' ).puntor.align-items-center
          v-icon(v-if='ruta==ruta_actual' x-small left color='rojo' dark absolute style='cursor: pointer') fas fa-angle-right
          v-icon(v-else x-small left color='white' dark absolute style='cursor: pointer') fas fa-angle-right
          div.puntor.mt-3
            h3(v-if='ruta==ruta_actual' v-html='texto' style='cursor: pointer').rojo--text.font-weight-medium 
            h3(v-else v-html='texto' style='cursor: pointer').white--text.font-weight-medium


</template>
<script>
export default {
  computed: {
    ruta_actual(){
        return this.$route.name
    },
  },
}
</script>
<style lang="sass" scoped>
.puntor
  cursor: pointer
</style>